import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  IconButton,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { auth, database } from "../../firebase"; // Import Firebase
import { ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Profile = () => {
  const [savedResources, setSavedResources] = useState([]);
  const [comments, setComments] = useState([]);
  const user = auth.currentUser;
  const navigate = useNavigate();

  // Fetch saved resources from Realtime Database
  const fetchSavedResources = useCallback(() => {
    const favoritesRef = ref(database, `users/${user.uid}/favorites`);
    onValue(favoritesRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const savedArray = Object.values(data);
        setSavedResources(savedArray);
      } else {
        setSavedResources([]);
      }
    });
  }, [user.uid]);

  // Fetch comments from Realtime Database
  const fetchComments = useCallback(() => {
    const commentsRef = ref(database, `users/${user.uid}/comments`);
    onValue(commentsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const commentsArray = Object.values(data);
        setComments(commentsArray);
      } else {
        setComments([]);
      }
    });
  }, [user.uid]);

  useEffect(() => {
    if (user) {
      fetchSavedResources();
      fetchComments();
    }
  }, [fetchComments, fetchSavedResources, user]);

  return (
    <Box sx={{ padding: "16px" }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ ml: 2 }}>
          My Profile
        </Typography>
      </Box>

      {/* Saved Resources Section */}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Saved Resources
      </Typography>
      <List sx={{ mb: 4 }}>
        {savedResources.length > 0 ? (
          savedResources.map((resource, index) => (
            <ListItem key={index} sx={{ mb: 2 }}>
              <Card sx={{ width: "100%" }}>
                <CardContent>
                  <Typography variant="h6">{resource.resourceId}</Typography>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/resources/${resource.resourceId}`, {
                        state: { resourceId: resource.resourceId },
                      })
                    }
                  >
                    View Resource
                  </Button>
                </CardContent>
              </Card>
            </ListItem>
          ))
        ) : (
          <Typography variant="body2">
            You have not saved any resources yet.
          </Typography>
        )}
      </List>

      <Divider sx={{ my: 2 }} />

      {/* Comments Section */}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Comments
      </Typography>
      <List>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <ListItem key={index} alignItems="flex-start" sx={{ mb: 2 }}>
              <Avatar
                alt={comment.userName}
                src={comment.userPhoto}
                sx={{ mr: 2 }}
              />
              <ListItemText
                primary={
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {comment.userName}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {comment.comment}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ display: "block", color: "text.secondary" }}
                    >
                      {new Date(comment.timestamp).toLocaleString()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body2">
            You have not commented on any resources yet.
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default Profile;
