import React, { useState } from "react";
import { Box, Typography, Pagination, Card } from "@mui/material";
import { useParams } from "react-router-dom";
import ResourceCard from "../ResourceCard/ResourceCard";
import Googlemap from "../../GoogleMap/GoogleMap";

const ResourceList = () => {
  const { category } = useParams(); // Get the category from the URL
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  // Calculate pagination
  const displayedResources = resources.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center" }}>
        Nearby {category.charAt(0).toUpperCase() + category.slice(1)} Resources
      </Typography>

      {/* Google Map Component to Fetch Real-time Resources */}
      <Card sx={{ p: 2 }}>
        <Googlemap category={category} onResourcesFetched={setResources} />
      </Card>

      {/* Pagination for Resource Cards */}
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <Pagination
          count={Math.ceil(resources.length / itemsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Box>

      {/* Render Resource Cards */}
      <Box
        sx={{
          gap: "30px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {displayedResources.length > 0 ? (
          displayedResources.map((resource, index) => (
            <ResourceCard key={index} resource={resource} category={category} />
          ))
        ) : (
          <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
            No {category} resources found nearby.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ResourceList;
