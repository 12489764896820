import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  CardMedia,
  IconButton,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Collapse,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DirectionsIcon from "@mui/icons-material/Directions";
import ShareIcon from "@mui/icons-material/Share";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import CommentIcon from "@mui/icons-material/Comment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, auth } from "../../../firebase";
import Googlemap from "../../GoogleMap/GoogleMap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ResourceCard = ({ resource, onResourceUpdated }) => {
  const [expanded, setExpanded] = useState(false);
  const [showHours, setShowHours] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editComment, setEditComment] = useState(null);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [directionsVisible, setDirectionsVisible] = useState(false);

  const handleExpandClick = () => {
    setDirectionsVisible(!directionsVisible);
    setExpanded(!expanded);
  };

  const handleCommentDialogOpen = () => {
    setCommentDialogOpen(true);
  };

  const handleCommentDialogClose = () => {
    setEditComment(null);
    setCommentDialogOpen(false);
  };

  const fetchComments = useCallback(async () => {
    const commentsSnapshot = await getDocs(
      collection(db, `resources/${resource.id}/comments`)
    );
    const commentsList = commentsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setComments(commentsList);
  }, [resource.id]);

  const handleLike = async () => {
    try {
      const resourceRef = doc(db, "resources", resource.id);
      const newLikes = liked ? likes - 1 : likes + 1;
      await updateDoc(resourceRef, { likes: newLikes });
      setLikes(newLikes);
      setLiked(!liked);
      if (onResourceUpdated) onResourceUpdated(resource.id, newLikes);
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleShare = () => {
    const shareData = {
      title: resource.name,
      text: `Check out this resource: ${resource.name} located at ${resource.address}.`,
      url: window.location.href,
    };
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Resource shared successfully."))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      alert(`Resource: ${resource.name} copied to clipboard!`);
      navigator.clipboard.writeText(`${shareData.text}\n${shareData.url}`);
    }
  };

  const handleCommentSubmit = async () => {
    if (newComment.trim() === "") return;
    try {
      if (editComment) {
        await updateDoc(
          doc(db, `resources/${resource.id}/comments`, editComment.id),
          { text: newComment }
        );
        setEditComment(null);
      } else {
        await addDoc(collection(db, `resources/${resource.id}/comments`), {
          text: newComment,
          user: auth.currentUser?.displayName || "Anonymous",
          createdAt: new Date().toISOString(),
        });
      }
      setNewComment("");
      fetchComments();
      handleCommentDialogClose();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleEditComment = (comment) => {
    setEditComment(comment);
    setNewComment(comment.text);
    setCommentDialogOpen(true);
  };

  const handleDeleteComment = async (commentId) => {
    await deleteDoc(doc(db, `resources/${resource.id}/comments`, commentId));
    fetchComments();
  };

  useEffect(() => {
    fetchComments();
    setLikes(resource.likes || 0);
    setLiked(false);
  }, [fetchComments, resource]);

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  return (
    <Card
      sx={{
        width: { xs: "200px", md: "300px" },
        height: "auto",
        borderRadius: "16px",
        boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
        "&:hover": {
          transform: "scale(1.05)",
          transition: "0.3s",
        },
      }}
    >
      {resource.photos ? (
        <CardMedia
          component="img"
          height="140"
          image={`${resource.photos[0].getUrl({
            maxHeight: 300,
            maxWidth: 400,
          })}`}
          alt={resource.name}
        />
      ) : (
        <CardMedia
          component="img"
          height="140"
          image="https://via.placeholder.com/400x300.png?text=No+Image+Available"
          alt={resource.name}
        />
      )}
      <CardContent>
        <Box>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {resource.name}
          </Typography>
        </Box>
        <Divider />

        <Box sx={{ p: 1 }}>
          <Chip
            icon={<EmailIcon />}
            label={`Email: ${resource.email || "Not Available"}`}
            variant="outlined"
          />
        </Box>

        <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
          <Chip
            icon={<PhoneIcon />}
            label={`Phone: ${resource.phone || "Not Available"}`}
            variant="outlined"
          />
          <Chip
            icon={<AccessTimeIcon />}
            label="Hours"
            clickable
            onClick={() => setShowHours(!showHours)}
            variant="outlined"
          />
        </Box>

        <Box sx={{ p: 1 }}>
          <Chip
            label={`Status: ${resource.status}`}
            color={resource.status === "Open" ? "success" : "error"}
          />
        </Box>

        {/* Hours Pop-up */}
        <Dialog open={showHours} onClose={() => setShowHours(false)}>
          <DialogTitle>Operating Hours</DialogTitle>
          <DialogContent>
            {resource.time.split(", ").map((time, index) => (
              <Typography key={index}>{time}</Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowHours(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Box>
            <IconButton
              onClick={handleLike}
              color={liked ? "error" : "default"}
            >
              {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
            <IconButton onClick={handleCommentDialogOpen}>
              <CommentIcon />
            </IconButton>
            <IconButton onClick={handleExpandClick}>
              <DirectionsIcon />
            </IconButton>
            <IconButton onClick={handleShare}>
              <ShareIcon />
            </IconButton>
          </Box>
          <Button
            onClick={() => toggleExpand(resource.id)}
            endIcon={
              expanded[resource.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
            sx={{ color: "red" }}
          >
            {expanded[resource.id]}
          </Button>
        </Box>

        <Collapse in={expanded[resource.id]} timeout="auto" unmountOnExit>
          <Typography variant="body2" sx={{ mt: 1, textAlign: "left" }}>
            <strong>Full Address : </strong> {resource.address}
          </Typography>
        </Collapse>

        {directionsVisible && (
          <Box sx={{ mt: 2 }}>
            <Googlemap
              center={{ lat: resource.lat, lng: resource.lng }}
              destination={{ lat: resource.lat, lng: resource.lng }}
              directions={true}
            />
          </Box>
        )}

        {/* Comments Modal */}
        <Dialog open={commentDialogOpen} onClose={handleCommentDialogClose}>
          <DialogTitle>
            {editComment ? "Edit Comment" : "Add a Comment"}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              variant="outlined"
              label="Your Comment"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <Box sx={{ mt: 2 }}>
              {comments.map((comment) => (
                <Box key={comment.id} sx={{ mb: 2 }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {comment.user}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {comment.text}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    <Button
                      size="small"
                      onClick={() => handleEditComment(comment)}
                    >
                      Edit
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDeleteComment(comment.id)}
                      color="error"
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCommentDialogClose}>Cancel</Button>
            <Button
              onClick={handleCommentSubmit}
              variant="contained"
              color="primary"
            >
              {editComment ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default ResourceCard;
