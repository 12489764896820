import React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Profile from "./components/Profile/Profile";
import ResourceDetail from "./components/Resources/ResourceDetail/ResourceDetail";
import Header from "./components/Header/Header";
import ResourceListScreen from "./components/Resources/ResourceList/ResourceListScreen";
import WomenResourcesScreen from "./components/Resources/WomenResourcesScreen";
import LGBTQIAResourcesScreen from "./components/Resources/LGBTQIA";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Login from "./components/Login/Login";

function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/women-resources"
          element={
            <ProtectedRoute>
              <WomenResourcesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/women-resources/:category"
          element={
            <ProtectedRoute>
              <ResourceListScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/lgbtqia-resources"
          element={
            <ProtectedRoute>
              <LGBTQIAResourcesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/lgbtqia-resources/:category"
          element={
            <ProtectedRoute>
              <ResourceListScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resources/:resourceId"
          element={
            <ProtectedRoute>
              <ResourceDetail />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<div>404 - Page Not Found</div>} />{" "}
      </Routes>
    </Box>
  );
}

export default App;
