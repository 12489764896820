import React, { useState } from "react";
import {
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PsychologyIcon from "@mui/icons-material/Psychology";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import SpaIcon from "@mui/icons-material/Spa";
import ShieldIcon from "@mui/icons-material/Shield";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useNavigate } from "react-router-dom";

// Define categories and their descriptions for LGBTQIA resources
const categories = [
  {
    name: "Healthcare",
    icon: <LocalHospitalIcon />,
    path: "/lgbtqia-resources/healthcare",
    description: "Inclusive healthcare services for LGBTQIA+ individuals.",
  },
  {
    name: "Mental Health",
    icon: <PsychologyIcon />,
    path: "/lgbtqia-resources/mental-health",
    description: "Emotional and psychological support for LGBTQIA+.",
  },
  {
    name: "Legal Aid",
    icon: <GavelIcon />,
    path: "/lgbtqia-resources/legal-aid",
    description: "Legal assistance and resources for LGBTQIA+ rights.",
  },
  {
    name: "Support Groups",
    icon: <GroupIcon />,
    path: "/lgbtqia-resources/support-groups",
    description: "Community support groups and peer support.",
  },
  {
    name: "Education",
    icon: <SchoolIcon />,
    path: "/lgbtqia-resources/education",
    description: "Educational resources and scholarships for LGBTQIA+.",
  },
  {
    name: "Career",
    icon: <WorkIcon />,
    path: "/lgbtqia-resources/career",
    description:
      "Career development and job support for LGBTQIA+ professionals.",
  },
  {
    name: "Safety",
    icon: <ShieldIcon />,
    path: "/lgbtqia-resources/safety",
    description: "Resources and support for safety and anti-violence.",
  },
  {
    name: "Leadership",
    icon: <SupervisorAccountIcon />,
    path: "/lgbtqia-resources/leadership",
    description: "Leadership programs and events for LGBTQIA+ individuals.",
  },
  {
    name: "Wellness",
    icon: <SpaIcon />,
    path: "/lgbtqia-resources/wellness",
    description: "Wellness programs and fitness activities for LGBTQIA+.",
  },
];

const LGBTQIA = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Filter categories based on the search term
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategoryClick = (categoryPath) => {
    navigate(categoryPath);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ color: "#6A1B9A", textAlign: "center", mb: 4 }}
      >
        LGBTQIA+ Resources
      </Typography>

      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "60%", borderRadius: "50px", backgroundColor: "#fff" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Category List in Grid Layout */}
      <Grid container spacing={3} justifyContent="center">
        {filteredCategories.map((category, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <ListItem
              button
              onClick={() => handleCategoryClick(category.path)}
              sx={{
                borderRadius: "12px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                transition: "0.3s",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                  transform: "scale(1.02)",
                },
                padding: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ListItemIcon sx={{ color: "#8e24aa", fontSize: 40 }}>
                {category.icon}
              </ListItemIcon>
              <ListItemText
                primary={category.name}
                primaryTypographyProps={{
                  variant: "h6",
                  fontWeight: "bold",
                  color: "#6A1B9A",
                }}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {category.description}
              </Typography>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LGBTQIA;
