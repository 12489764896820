import React, { useState } from "react";
import {
  TextField,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import PsychologyIcon from "@mui/icons-material/Psychology";
import GavelIcon from "@mui/icons-material/Gavel";
import SecurityIcon from "@mui/icons-material/Security";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import SpaIcon from "@mui/icons-material/Spa";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SupportIcon from "@mui/icons-material/Group";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HomeIcon from "@mui/icons-material/Home";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { useNavigate } from "react-router-dom";

// Define categories and descriptions for Women resources
const categories = [
  {
    name: "Healthcare",
    icon: <LocalHospitalIcon fontSize="large" />,
    description: "Women health services and facilities",
  },
  {
    name: "Mental Health",
    icon: <PsychologyIcon fontSize="large" />,
    description: "Emotional and psychological support",
  },
  {
    name: "Legal Aid",
    icon: <GavelIcon fontSize="large" />,
    description: "Legal help for women in need",
  },
  {
    name: "Safety",
    icon: <SecurityIcon fontSize="large" />,
    description: "Safety resources and emergency contacts",
  },
  {
    name: "Childcare",
    icon: <ChildCareIcon fontSize="large" />,
    description: "Childcare and support services",
  },
  {
    name: "Education",
    icon: <SchoolIcon fontSize="large" />,
    description: "Education programs and training",
  },
  {
    name: "Career",
    icon: <WorkIcon fontSize="large" />,
    description: "Career guidance and job support",
  },
  {
    name: "Financial",
    icon: <AttachMoneyIcon fontSize="large" />,
    description: "Financial advice and grants",
  },
  {
    name: "Leadership",
    icon: <TrendingUpIcon fontSize="large" />,
    description: "Leadership programs and events",
  },
  {
    name: "Wellness",
    icon: <SpaIcon fontSize="large" />,
    description: "Women’s wellness and fitness",
  },
  {
    name: "Support Groups",
    icon: <SupportIcon fontSize="large" />,
    description: "Support groups and community help",
  },
  {
    name: "Fitness",
    icon: <FitnessCenterIcon fontSize="large" />,
    description: "Fitness programs and activities",
  },
  {
    name: "Housing",
    icon: <HomeIcon fontSize="large" />,
    description: "Housing support and shelters",
  },
  {
    name: "Food & Nutrition",
    icon: <RestaurantIcon fontSize="large" />,
    description: "Food security and nutrition advice",
  },
];

const WomenResources = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategoryClick = (category) => {
    navigate(`/women-resources/${category.name.toLowerCase()}`);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", color: "#6A1B9A", marginBottom: 4 }}
      >
        Women Resources
      </Typography>

      {/* Search Bar */}
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 4 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "60%",
            borderRadius: "50px",
            backgroundColor: "#ffffff",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Category Grid */}
      <Grid container spacing={4} justifyContent="center">
        {filteredCategories.map((category, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardActionArea onClick={() => handleCategoryClick(category)}>
                <CardContent sx={{ textAlign: "center" }}>
                  <Box sx={{ color: "#8e24aa", marginBottom: 1 }}>
                    {category.icon}
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#6A1B9A", fontWeight: "bold" }}
                  >
                    {category.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {category.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WomenResources;
