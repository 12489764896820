import React, { useState, useEffect, useCallback } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const mapContainerStyle = {
  width: "100%",
  height: "300px", // Adjust map height for better visibility
};

const defaultCenter = {
  lat: 20.5937,
  lng: 78.9629,
};

// Define advanced keywords for each category based on detailed requirements
const categoryKeywords = {
  healthcare: [
    "women's health services",
    "gynecology clinics",
    "obstetrics care",
    "reproductive health",
    "breast health screening",
    "prenatal care",
    "family planning services",
  ],
  mentalhealth: [
    "mental health resources",
    "mental wellness",
    "emotional well-being",
    "psychological support",
    "behavioral health services",
    "depression support",
    "therapy services",
    "counseling centers",
    "group therapy",
    "self-help programs",
  ],
  legal: [
    "legal aid for women",
    "family law assistance",
    "domestic violence legal help",
    "women rights advocacy",
    "child custody legal aid",
    "sexual harassment legal resources",
    "legal counseling for women",
  ],
  safety: [
    "domestic violence shelters",
    "women crisis centers",
    "safety planning for women",
    "self-defense classes",
    "stalking support services",
    "intimate partner violence resources",
  ],
  childcare: [
    "affordable daycare",
    "single mother childcare assistance",
    "early childhood education",
    "after-school programs",
    "nanny services",
    "childcare providers",
  ],
  education: [
    "women education programs",
    "scholarships for women",
    "STEM programs for girls",
    "adult education for women",
    "women vocational training",
    "career training for women",
  ],
  career: [
    "job search assistance for women",
    "career counseling for women",
    "networking events for women",
    "career advancement",
    "entrepreneurship for women",
  ],
  financial: [
    "financial planning for women",
    "women investment groups",
    "retirement planning for women",
    "grants for women",
    "microloans for women entrepreneurs",
  ],
  leadership: [
    "female leadership development",
    "women in leadership conferences",
    "mentorship programs for women",
    "leadership coaching for women",
    "public speaking for women",
  ],
  wellness: [
    "fitness classes for women",
    "nutrition advice for women",
    "yoga classes for women",
    "women health retreats",
    "mindfulness for women",
    "stress reduction techniques",
  ],
  "support-groups": [
    "support groups for women",
    "community support",
    "group therapy for women",
  ],
};

const Googlemap = ({
  category = "healthcare",
  onResourcesFetched = () => {},
  center,
}) => {
  const [userLocation, setUserLocation] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [nearbyResources, setNearbyResources] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const fetchNearbyPlaces = useCallback(
    (location, keywords) => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps library is not loaded!");
        return;
      }

      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      let detailedResources = [];

      keywords.forEach((keyword) => {
        const request = {
          location,
          radius: "5000",
          keyword: keyword,
        };

        service.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            results.forEach((place) => {
              const detailsRequest = {
                placeId: place.place_id,
                fields: [
                  "name",
                  "vicinity",
                  "geometry",
                  "formatted_phone_number",
                  "business_status",
                  "opening_hours",
                  "photos",
                ],
              };

              service.getDetails(
                detailsRequest,
                (placeDetails, detailsStatus) => {
                  if (
                    detailsStatus ===
                    window.google.maps.places.PlacesServiceStatus.OK
                  ) {
                    const photoUrl =
                      placeDetails.photos &&
                      placeDetails.photos.length > 0 &&
                      `${placeDetails.photos[0].getUrl({
                        maxHeight: 300,
                        maxWidth: 400,
                      })}`;

                    const formattedResource = {
                      name: placeDetails.name,
                      address: placeDetails.vicinity,
                      lat: placeDetails.geometry.location.lat(),
                      lng: placeDetails.geometry.location.lng(),
                      phone: placeDetails.formatted_phone_number || "N/A",
                      email: placeDetails.email || "N/A",
                      status: placeDetails.opening_hours?.isOpen()
                        ? "Open"
                        : "Closed",
                      time:
                        placeDetails.opening_hours?.weekday_text?.join(", ") ||
                        "Not Available",
                      photoUrl,
                    };

                    detailedResources.push(formattedResource);

                    // Ensure all details are fetched before updating the state
                    if (
                      detailedResources.length ===
                      keywords.length * results.length
                    ) {
                      onResourcesFetched(detailedResources);
                      setNearbyResources(detailedResources);
                    }
                  }
                }
              );
            });
          } else {
            console.error("PlacesService request failed with status:", status);
            onResourcesFetched([]);
          }
        });
      });
    },
    [onResourcesFetched]
  );

  useEffect(() => {
    const keywords = categoryKeywords[category.toLowerCase()] || [
      "women health services",
    ];
    if (isLoaded && window.google && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userPos);
          fetchNearbyPlaces(userPos, keywords);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocation(defaultCenter);
          fetchNearbyPlaces(defaultCenter, keywords);
        }
      );
    } else if (isLoaded && window.google) {
      console.log("Geolocation is not supported by this browser.");
      setUserLocation(defaultCenter);
      fetchNearbyPlaces(defaultCenter, keywords);
    }
  }, [category, fetchNearbyPlaces, isLoaded]);

  // Fetch nearby places using multiple keyword queries

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps...</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center || userLocation || defaultCenter}
      zoom={14}
    >
      {userLocation && (
        <Marker
          position={userLocation}
          icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        />
      )}
      {nearbyResources.map((resource, index) => (
        <Marker
          key={index}
          position={{ lat: resource.lat, lng: resource.lng }}
          onClick={() => setSelectedMarker(resource)}
          icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
        />
      ))}
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h3>{selectedMarker.name}</h3>
            <p>{selectedMarker.address}</p>
            <p>
              <strong>Phone:</strong> {selectedMarker.phone}
            </p>
            <p>
              <strong>Status:</strong> {selectedMarker.status}
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

export default Googlemap;
